import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import Dev from "../dev/Dev"

const Header = () => {

    function scrollFunction() {
        if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
            if (document.querySelector("#header.header-sticky") === null) {
                document.getElementById("header").classList.add("header-sticky");
            }    
        } else {
            if (document.querySelector("#header.header-sticky") !== null) {
                document.getElementById("header").classList.remove("header-sticky");
            }    
        }
    }
    
      
    // sticky header
    if (typeof window !== `undefined`) {
        window.onscroll = function () {
            scrollFunction()
        };
    }

    const closeMenu = (e) => {
        const nav = document.querySelector('.mobile-menu')
        const body = document.querySelector('body')
        const hamburger = document.querySelector('.hamburger--collapse')
    
        if (nav.classList.contains("nav-animation") !== null) {
            hamburger.classList.remove("is-active")
            nav.classList.remove("nav-animation")
            nav.classList.add("nav-animation-close")
            body.classList.remove('no-scroll')
        } 
    }

    const openMenu = (e) => {
        e.preventDefault()
        const nav = document.querySelector('.mobile-menu')
        const body = document.querySelector('body')
        const hamburger = document.querySelector('.hamburger--collapse')

        
    
        if (nav.classList.contains("nav-animation") === false) {
            hamburger.classList.add("is-active")
            nav.classList.remove("nav-animation-close")
            nav.classList.add("nav-animation")
    
            body.classList.add('no-scroll')    
        } 

    }    

    const visitLink = (e, link) => {
        e.preventDefault()
        const nav = document.querySelector('.mobile-menu')
        const body = document.querySelector('body')
        const hamburger = document.querySelector('.hamburger--collapse')
    
        if (nav.classList.contains("nav-animation") !== null) {
            hamburger.classList.remove("is-active")
            nav.classList.remove("nav-animation")
            nav.classList.add("nav-animation-close")
            body.classList.remove('no-scroll')
        } 
        navigate(link)
    }    
    
    return (
        <>
            <header id="header">
                <div className="logo">
                    <Link to="/"><Dev />  Chris Bartlett</Link>
                </div>
           
                <div className="hamburger-menu">
                        <div className="hamburger-wrapper">
                            <button className="hamburger menu-link hamburger--collapse" type="button" tabIndex={0} onClick={(e) => { openMenu(e) }} >
                                <span className="hamburger-box">
                                    <span className="hamburger-inner"></span>
                                </span>
                            </button>
                        </div>        
                </div>
            </header>
            <div className="mobile-menu bg-pan-left">
                <div className="mobile-menu-header">

                    <div className="menu-header-right">
                        <button className="hamburger menu-link hamburger--collapse is-active close" type="button" tabIndex={0} onClick={(e) => { closeMenu(e) }} >
                            <span className="hamburger-box">
                                <span className="hamburger-inner"></span>
                            </span>
                        </button>
                    </div>
                </div>        
        
                <nav>
                    <ul>
                        <li><Link to="/" onClick={(e) => { visitLink(e, "/") }} >Home</Link></li>
                        <li><Link to="/about" onClick={(e) => { visitLink(e, "/about") }} >About Me</Link></li>
                        <li><Link to="/portfolio" onClick={(e) => { visitLink(e, "/portfolio") }} >Portfolio</Link></li>
                        <li><Link to="/ecommerce-web-development" onClick={(e) => { visitLink(e, "/ecommerce-web-development") }} >Ecommerce</Link></li>
                        <li><Link to="/web-development" onClick={(e) => { visitLink(e, "/web-development") }} >Web Development</Link></li>
                        <li><Link to="/partnerships" onClick={(e) => { visitLink(e, "/partnerships") }} >Partnerships</Link></li>
                        <li><Link to="/frequently-asked-questions" onClick={(e) => { visitLink(e, "/frequently-asked-questions") }} >FAQ</Link></li>
                    </ul>
                </nav>
            </div>
        </>
        )}
        
        Header.propTypes = {
          siteTitle: PropTypes.string,
        }
        
        Header.defaultProps = {
          siteTitle: ``,
        }
        
        
        
        
        
        export default Header
        