import React from "react"
import { Link, graphql } from "gatsby"
 
import Layout from "../components/layout/Layout"



export default function Template({
	data, // this prop will be injected by the GraphQL query below.
	pageContext
}) {
	const { markdownRemark } = data // data.markdownRemark holds your post data
	const { frontmatter, html } = markdownRemark
	const { next, prev } = pageContext
	return (

		<div className="tech-detail">
				<Layout>          

					{prev && 
							<Link to={`/tech${prev.fields.slug}`}><span className="icon-arrow_left1"></span></Link>
					}

					<h2>{frontmatter.title}</h2>

					{next && 
							<Link to={`/tech${next.fields.slug}`}><span className="icon-arrow_right1"></span></Link>
					}         

						

						<section className="tech-detail-content">
								<div className="main-content"
										dangerouslySetInnerHTML={{ __html: html }}
								/>
 

						</section>
				</Layout>
		</div>
		
		
	)
}

export const pageQuery = graphql`
	query techBySlug($slug: String!) {
		site {
			siteMetadata {
				title
			}
		}
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			frontmatter {
				title      
			}
		} 
		techs: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, limit: 20, filter: {frontmatter: {post_type: {eq: "tech"}}}) {
					edges {
						node {
							fields {
								slug
							}
							frontmatter {
								title
								post_type
							}
						}
					}
				} 
	}
`